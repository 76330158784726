import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FiLogOut, FiHome, FiBook } from 'react-icons/fi'; // Updated with a book icon for Mermaid diagram

const NavBar: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  const getUserInitials = () => {
    if (user && user.name) {
      const nameParts = user.name.split(' ');
      return `${nameParts[0][0].toUpperCase()}${nameParts[1]?.[0]?.toUpperCase() || ''}`;
    }
    return 'U'; // Default initial if no name is available
  };

  return (
    <aside className="h-screen w-16 bg-gray-800 text-white flex flex-col items-center py-4 fixed">
      {/* Logo */}
      <div className="mb-6">
        <img src="/vdz_logo.png" alt="App Logo" className="h-8" />
      </div>

      {/* Menu items at the top */}
      <nav className="flex-grow flex flex-col items-center space-y-4">
        <a href="/" className="hover:text-gray-400">
          <FiHome className="w-6 h-6" />
        </a>
        <a href="/dashboard" className="hover:text-gray-400">
          <FiBook className="w-6 h-6" /> {/* Book icon for Mermaid diagram */}
        </a>
      </nav>

      {/* Profile and Logout at the bottom */}
      {isAuthenticated && user && (
        <div className="flex flex-col items-center space-y-4 mb-4">
          {/* Profile with user initials - clickable to Profile page */}
          <a href="/profile" className="w-10 h-10 bg-gray-500 rounded-full flex items-center justify-center text-xl font-bold hover:bg-gray-600">
            {getUserInitials()}
          </a>
          {/* Logout */}
          <button
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            className="hover:text-gray-400 focus:outline-none"
          >
            <FiLogOut className="w-6 h-6" />
          </button>
        </div>
      )}
    </aside>
  );
};

export default NavBar;
