import React, { ReactNode, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { auth0Config } from './auth0Config';

interface AppState {
  returnTo?: string;
}

interface AuthProviderWithNavigateProps {
  children: ReactNode;
}


const AuthProviderWithNavigate: React.FC<AuthProviderWithNavigateProps> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      navigate(appState?.returnTo || '/dashboard', { replace: true });
    },
    [navigate]
  );

  return (
<Auth0Provider
    domain="vzd.eu.auth0.com"
    clientId="TlfnDh47h07VEPGDKct9OGi3VMcsT8wv"
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: auth0Config.redirectUri,
        scope: 'openid profile email',  // Scopes for your API
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <AuthProviderWithNavigate>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AuthProviderWithNavigate>
  </BrowserRouter>
);