import React, { useState, useRef } from 'react';
import axios from 'axios';
import { FiChevronDown, FiMessageSquare, FiUpload } from 'react-icons/fi'; // Import icons
import { AudioRecorder } from 'react-audio-voice-recorder';

interface ChatBoxProps {
  onFileContentUpdate: (content: string) => void;
  selectedProvider: string;
}

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

const ChatBox: React.FC<ChatBoxProps> = ({ onFileContentUpdate, selectedProvider }) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatHistoryOpen, setIsChatHistoryOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null); // Ref for the textarea

  // Function to handle textarea input change and auto-resize the textarea
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    e.target.style.height = 'auto'; // Reset height to auto
    e.target.style.height = `${Math.min(e.target.scrollHeight, window.innerHeight * 0.2)}px`; // Dynamically adjust, capped at 20% of screen height
  };

  // Function to handle file input changes for uploading files
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setInput(''); // Clear input when a file is selected
    } else {
      setSelectedFile(null);
    }
  };

  // Function to handle audio recording and upload
  const handleAudioData = async (blob: Blob) => {
    console.log('Audio recording saved', blob);

    const formData = new FormData();
    formData.append('audio', blob, 'recording.wav');

    try {
      setIsLoading(true);

      const response = await axios.post('https://ca-audio-service.marcel-gocke.workers.dev', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const transcript = response.data.response.result.text || 'Unable to transcribe audio.';

      setInput(transcript);

      const assistantMessage: Message = {
        role: 'assistant',
        content: 'Audio processed and transcript received. Review and submit to generate the diagram.',
      };

      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
    } catch (error) {
      console.error('Error processing audio:', error);
      setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: 'Error processing audio.' }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle submission of the text or file
  const handleSubmit = async () => {
    if (isLoading) return; // Prevent multiple submissions if a request is ongoing

    const apiEndpoints = {
      CloudAhead: 'https://ca-ai-worker.marcel-gocke.workers.dev',
      PD: 'https://pd-ai-worker.marcel-gocke.workers.dev',
    };

    const apiUrl = selectedProvider === 'CloudAhead' ? apiEndpoints.CloudAhead : apiEndpoints.PD;

    setIsLoading(true);
    try {
      if (selectedFile) {
        // Handle file upload (either text or video)
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await axios.post('https://cas3upload.marcel-gocke.workers.dev/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const fileUrl = `https://mgockevdz.s3.us-east-1.amazonaws.com/${response.data.fileName}`; // Construct the URL

        const assistantMessage: Message = {
          role: 'assistant',
          content: response.data.message || 'File uploaded successfully',
        };

        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
        onFileContentUpdate(fileUrl); // Send the file URL to be processed

        // If the file is a video, send the video URL to the AI worker
        if (selectedFile.type === 'video/mp4' || selectedFile.type === 'video/quicktime') {
          const aiResponse = await axios.post(apiUrl, { videoUrl: fileUrl }, { headers: { 'Content-Type': 'application/json' } });
          const mermaidCode = aiResponse.data.message || 'No response from AI';

          const videoProcessingMessage: Message = {
            role: 'assistant',
            content: mermaidCode,
          };

          setMessages((prevMessages) => [...prevMessages, videoProcessingMessage]);
          onFileContentUpdate(mermaidCode); // Update the file content in the parent component
        }

        setSelectedFile(null); // Clear the selected file after processing
      } else if (input.trim() !== '') {
        // Handle text input submission
        const userMessage: Message = { role: 'user', content: input };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInput(''); // Clear input after submission

        const response = await axios.post(
          apiUrl,
          { prompt: input },
          { headers: { 'Content-Type': 'application/json' } }
        );

        const mermaidCode = response.data.message.replace(/```mermaid\s*([\s\S]*?)\s*```/g, '$1').trim();
        const assistantMessage: Message = {
          role: 'assistant',
          content: mermaidCode || 'No response from AI',
        };

        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
        onFileContentUpdate(mermaidCode); // Update the file content in the parent component

        // Reset the textarea height after submission
        if (inputRef.current) {
          inputRef.current.style.height = '40px';
        }
      }
    } catch (error) {
      console.error('Error during submission:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: 'Error during submission.' },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[60%] bg-white rounded-lg shadow-lg z-50 p-4">
      {isChatHistoryOpen && (
        <div className="max-h-64 overflow-y-auto bg-white shadow-md p-4">
          {messages.map((msg, index) => (
            <div key={index} className={`mb-2 ${msg.role === 'user' ? 'text-right' : 'text-left'}`}>
              <span
                className={`inline-block p-2 rounded-lg ${msg.role === 'user' ? 'bg-green-200' : 'bg-blue-200'}`}
              >
                {msg.content}
              </span>
            </div>
          ))}
        </div>
      )}

      <div className="flex items-center bg-white p-2 space-x-2 relative">
        <button
          onClick={() => setIsChatHistoryOpen(!isChatHistoryOpen)}
          className="bg-gray-200 p-2 rounded-full flex items-center justify-center"
        >
          {isChatHistoryOpen ? <FiChevronDown className="w-6 h-6" /> : <FiMessageSquare className="w-6 h-6" />}
        </button>

        {selectedFile ? (
          <div className="flex items-center flex-grow">
            <p className="truncate">{selectedFile.name}</p>
            <button onClick={() => setSelectedFile(null)} className="ml-2 text-red-500 hover:text-red-700">
              Remove
            </button>
          </div>
        ) : (
          <>
            <textarea
              ref={inputRef}
              value={input}
              onChange={handleInputChange}
              className="flex-grow h-10 border border-gray-300 rounded-l-lg p-2 resize-none"
              placeholder="Type your message..."
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  handleSubmit();
                  e.preventDefault();
                }
              }}
              disabled={isLoading || selectedFile !== null}
              rows={1}
              style={{ minHeight: '40px', maxHeight: `${window.innerHeight * 0.2}px` }}
            />
            <input type="file" accept=".txt,.mp4,.mov" onChange={handleFileChange} className="hidden" id="file-input" />
            <label htmlFor="file-input" className="bg-gray-300 text-white px-4 h-10 flex items-center cursor-pointer">
              <FiUpload className="w-5 h-5" />
            </label>
          </>
        )}

        <AudioRecorder onRecordingComplete={handleAudioData} />
        <button
          onClick={handleSubmit}
          className="bg-green-300 text-white px-4 h-10 rounded-r-lg"
          disabled={isLoading || (!selectedFile && input.trim() === '')}
        >
          {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
