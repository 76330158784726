/* eslint-disable */

import React, { useEffect, useRef, useState, useCallback } from 'react';
import mermaid from 'mermaid';
import { toPng } from 'html-to-image';

interface MermaidDiagramProps {
  initialChart: string;
  onDiagramGenerated: (base64Png: string) => void;
  onLabelSelect: (label: string, element: HTMLElement) => void;
}

const MermaidDiagram: React.FC<MermaidDiagramProps> = ({ initialChart, onDiagramGenerated, onLabelSelect }) => {
  const [chart, setChart] = useState<string>(initialChart);
  const diagramRef = useRef<HTMLDivElement>(null);
  const [highlightedNodeLabel, setHighlightedNodeLabel] = useState<string | null>(null); // Store the highlighted node label

  useEffect(() => {
    mermaid.initialize({ startOnLoad: false, theme: 'default' });
  }, []);

  useEffect(() => {
    setChart(initialChart); // Update the chart whenever the initial chart changes
  }, [initialChart]);

  const extractTextContentRecursive = (element: Node): string => {
    let textContent = '';
    if (element.nodeType === Node.TEXT_NODE) {
      textContent += element.textContent?.trim() || '';
    } else if (element.hasChildNodes()) {
      element.childNodes.forEach((child) => {
        textContent += extractTextContentRecursive(child);
      });
    }
    return textContent;
  };

  const handleElementClick = useCallback((element: HTMLElement) => {
    const label = extractTextContentRecursive(element);
    if (label) {
      onLabelSelect(label, element); // Pass label and element to MainLayout
      setHighlightedNodeLabel(label); // Store the label of the highlighted node
    }
  }, [onLabelSelect]);

  const addElementClickListeners = useCallback(() => {
    if (diagramRef.current) {
      const svgElements = diagramRef.current.querySelectorAll('g.node, g.edge');
      svgElements.forEach((element) => {
        const htmlElement = element as HTMLElement;
        htmlElement.style.cursor = 'pointer';
        htmlElement.addEventListener('click', () => handleElementClick(htmlElement));
      });
    }
  }, [handleElementClick]);

  const reapplyHighlight = useCallback(() => {
    if (highlightedNodeLabel && diagramRef.current) {
      const elementsToHighlight = diagramRef.current.querySelectorAll('g.node, g.edge');
      elementsToHighlight.forEach((element) => {
        const textContent = extractTextContentRecursive(element);
        if (textContent === highlightedNodeLabel) {
          const rectElement = element.querySelector('rect') as SVGElement;
          if (rectElement) {
            rectElement.setAttribute('stroke', 'url(#rainbow-gradient)');
            rectElement.setAttribute('stroke-width', '3px');
            rectElement.setAttribute('stroke-dasharray', '5, 5');
            rectElement.style.animation = 'sparkle 3s linear infinite';
          }
        }
      });

      const defsElement = diagramRef.current?.querySelector('defs');
      if (defsElement && !defsElement.querySelector('#rainbow-gradient')) {
        defsElement.innerHTML += `
          <linearGradient id="rainbow-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style="stop-color: #FF0000; stop-opacity: 1;" />
            <stop offset="20%" style="stop-color: #FF7F00; stop-opacity: 1;" />
            <stop offset="40%" style="stop-color: #FFFF00; stop-opacity: 1;" />
            <stop offset="60%" style="stop-color: #00FF00; stop-opacity: 1;" />
            <stop offset="80%" style="stop-color: #0000FF; stop-opacity: 1;" />
            <stop offset="100%" style="stop-color: #8B00FF; stop-opacity: 1;" />
          </linearGradient>
        `;
      }
    }
  }, [highlightedNodeLabel]);

  const generatePng = useCallback(() => {
    if (diagramRef.current) {
      toPng(diagramRef.current)
        .then((dataUrl) => {
          const base64Png = dataUrl.replace(/^data:image\/png;base64,/, '');
          onDiagramGenerated(base64Png);
        })
        .catch((error) => {
          console.error('Error generating PNG:', error);
        });
    }
  }, [onDiagramGenerated]);

  // Function to extend the viewBox by 20% to allow extra space around the diagram
  const extendViewBox = useCallback(() => {
    if (diagramRef.current) {
      const svgElement = diagramRef.current.querySelector('svg');
      if (svgElement) {
        const viewBox = svgElement.getAttribute('viewBox');
        if (viewBox) {
          const [minX, minY, width, height] = viewBox.split(' ').map(Number);
          const extendedWidth = width * 1.2; // Add 20% to width
          const extendedHeight = height * 1.2; // Add 20% to height

          // Adjust minX and minY to center the content within the extended viewbox
          const newMinX = minX - (extendedWidth - width) / 2;
          const newMinY = minY - (extendedHeight - height) / 2;

          svgElement.setAttribute('viewBox', `${newMinX} ${newMinY} ${extendedWidth} ${extendedHeight}`);
        }
      }
    }
  }, []);

  useEffect(() => {
    const renderChart = async () => {
      if (diagramRef.current && chart) {
        try {
          const id = 'mermaid-chart-' + Math.floor(Math.random() * 1000000);
          const { svg } = await mermaid.render(id, chart);
          diagramRef.current.innerHTML = svg;
          addElementClickListeners();
          generatePng();
          reapplyHighlight(); // Reapply the highlight after the chart re-renders
          extendViewBox(); // Extend the viewBox of the SVG
        } catch (error) {
          console.error('Mermaid Render Error:', error);
        }
      }
    };
    renderChart();
  }, [chart, addElementClickListeners, generatePng, reapplyHighlight, extendViewBox]);

  return (
    <div ref={diagramRef} className="mermaid w-full max-w-4xl text-center mx-auto mt-8" />
  );
};

export default MermaidDiagram;
