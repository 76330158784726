import React from 'react';
import NavBar from './NavBar';
import { useAuth0 } from '@auth0/auth0-react';

const ProfilePage: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <div>Loading...</div>;
  if (!isAuthenticated) return <div>Please log in</div>;

  return (
    <div className="min-h-screen bg-gray-100">
      <NavBar />
      <main className="container mx-auto p-8">
        <h1>Profile</h1>
        <div className="bg-white shadow-md p-6 rounded-lg">
          <img src={user?.picture} alt={user?.name} className="w-24 h-24 rounded-full mb-4" />
          <p>Name: {user?.name}</p>
          <p>Email: {user?.email}</p>
          <p>Nickname: {user?.nickname}</p>
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;
