import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LandingPage.css'; // Import the CSS for the gradient

const LandingPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="landing-page-container">
      {/* Header Section */}
      <header className="flex justify-between items-center py-8 px-8">
        <div className="flex items-center">
          <img src="/calogo.png" alt="App Logo" className="h-10" />
          <h1 className="ml-4 text-xl font-bold text-gray-700"> PD - Prozess-o-Mat</h1>
        </div>

        {/* Log In Button positioned on the right */}
        <button
          onClick={() => loginWithRedirect()}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Log In
        </button>
      </header>

      {/* Middle Section (Replaced with the content of the Additional Info Section) */}
      <section className="header-gradient bg-white py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">
            Revolutionize Your Process Documentation with Our AI-based Process Intelligence.
          </h2>
          <p className="text-center mb-4 text-black-400">
            Our tool makes process documentation and digitization simple and efficient.
          </p>
          <ul className="text-left list-disc list-inside text-gray-900 max-w-2xl mx-auto">
            <li className="mb-2">Interviews: Interview your key staff members for half an hour each.</li>
            <li className="mb-2">Upload: Upload the interviews into our tool.</li>
            <li className="mb-2">Process Diagram: Let the AI create integrated documentation from the interviews.</li>
            <li className="mb-2">Process Optimization: Optimize the automated documentation as you see fit.</li>
            <li className="mb-2">Digitization: Let the AI generate software code from your optimized processes.</li>
          </ul>

          {/* Add the Registration Button */}
          <button
            onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
            className="bg-blue-500 px-6 py-3 rounded mt-8"
          >
            Register Now!
          </button>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
