import React, { useState, useEffect } from 'react';
import ChatBox from './ChatBox';
import MermaidDiagram from './MermaidDiagram';
import axios from 'axios';
import NavBar from './NavBar';
import { toPng } from 'html-to-image';
import { useAuth0 } from '@auth0/auth0-react';

const MainLayout: React.FC = () => {
  const { user } = useAuth0();
  const defaultDiagram = `
    graph TD;
    A[Start] --> B{Is it sunny?};
    B -->|Yes| C[Go outside];
    B -->|No| D[Stay inside];
    C --> E[Enjoy the day];
    D --> E;
  `;

  const [fileContent, setFileContent] = useState<string>(defaultDiagram);

  const [email, setEmail] = useState<string>(''); 
  const [diagramPng, setDiagramPng] = useState<string>(''); 
  const [isEmailCollapsed, setIsEmailCollapsed] = useState<boolean>(false);
  const [isLLMModelCollapsed, setIsLLMModelCollapsed] = useState<boolean>(false);

  const [isLabelEditorCollapsed, setIsLabelEditorCollapsed] = useState<boolean>(true); // Label editor toggle
  const [selectedLabel, setSelectedLabel] = useState<string>(''); // Label for the selected node/edge
  const [selectedElementLabel, setSelectedElementLabel] = useState<string>(''); // Current label to replace

  // Initialize the email state when the component mounts
  useEffect(() => {
    if (user?.email) {
      setEmail(user.email); // Set the email state with the Auth0 user email
    }
  }, [user]);

  const handleFileContentUpdate = (content: string) => {
    setFileContent(content); // Update the fileContent state
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted with email:', email); // Debugging log to see email
    try {
      await axios.post('https://ca-email-service-production.marcel-gocke.workers.dev/api/sendemail', {
        email,
        diagramPng,
      });
      alert('Email sent successfully!');
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error sending email:', error.message);
        alert(`Error sending email: ${error.message}`); // Alert the error for better feedback
      } else {
        console.error('Unexpected error', error);
      }
    }
  };

  const handleDownloadPng = async () => {
    const diagramElement = document.querySelector('.mermaid') as HTMLElement; // Cast as HTMLElement
    if (!diagramElement) {
      alert('Diagram not found');
      return;
    }
    try {
      const pngDataUrl = await toPng(diagramElement); // Now it's casted as HTMLElement
      const downloadLink = document.createElement('a');
      downloadLink.href = pngDataUrl;
      downloadLink.download = 'diagram.png';
      downloadLink.click();
    } catch (error) {
      console.error('Error downloading PNG:', error);
    }
  };

  const renderCollapsibleBox = (
    title: string,
    isCollapsed: boolean,
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
    children: React.ReactNode
  ) => {
    return (
      <div className="bg-white p-4 shadow-md rounded-lg mb-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-sm font-bold">{title}</h3>
          <button
            className="text-xs text-blue-500"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? '▼' : '▲'}
          </button>
        </div>
        {!isCollapsed && (
          <div className="mt-3 text-sm text-gray-500">
            {children}
          </div>
        )}
      </div>
    );
  };

  const handleLabelUpdate = (label: string) => {
    setSelectedLabel(label); // Update the selected label in the editor
  };

  const handleApplyLabelChanges = () => {
    // Replace the label in the chart with the new one
    const updatedChart = fileContent.replace(selectedElementLabel, selectedLabel);
    setFileContent(updatedChart); // Update the Mermaid chart definition
    setSelectedElementLabel(''); // Hide the label editor after applying changes
  };

  return (
    <div 
      className="min-h-screen bg-gray-100 flex"
      style={{
        backgroundImage:
          'linear-gradient(to right, lightgray 0.5px, transparent 0.5px), linear-gradient(to bottom, lightgray 0.5px, transparent 0.5px)',
        backgroundSize: '10px 10px', // Finer and wider grid
      }}
    >
      <NavBar />

      {/* Left overlay with boxes */}
      <div className="w-64 bg-transparent fixed left-16 top-0 h-full p-4 z-10">
        {renderCollapsibleBox(
          'Diagramm versenden',
          isEmailCollapsed,
          setIsEmailCollapsed,
          <>
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                defaultValue={user?.email || ''} // Pre-fill with Auth0 user email, but allow user to edit
                onChange={(e) => {
                  console.log('Email input changed:', e.target.value); // Debugging log for email input
                  setEmail(e.target.value); // Update the email state when the user types
                }}
                className="w-full mb-4 p-2 border rounded"
                placeholder="Enter your email"
                required
              />
              <button
                type="submit"
                className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
              >
                Senden
              </button>
            </form>

            {/* Download as PNG button */}
            <button
              onClick={handleDownloadPng}
              className="w-full bg-blue-500 text-white py-2 px-4 mt-2 rounded hover:bg-blue-600"
            >
              Diagramm herunterladen
            </button>
          </>
        )}

        {renderCollapsibleBox(
          'Umgebung wählen',
          isLLMModelCollapsed,
          setIsLLMModelCollapsed,
          <>
            <div className="flex space-x-2 mb-2">
              {/* Top row: Cloud providers (fixed selection) */}
              <button className="bg-gray-200 px-2 py-1 rounded-full">Azure</button>
              <button className="bg-green-300 px-2 py-1 rounded-full" disabled>AWS</button>
              <button className="bg-gray-200 px-2 py-1 rounded-full" disabled>Delos</button>
            </div>

            {/* Fine dividing line */}
            <div className="border-t border-gray-300 my-2"></div>

            <div className="flex space-x-2">
              {/* Bottom row: LLM providers */}
              <button className="bg-green-300 px-2 py-1 rounded-full">GPT 4</button>
              <button className="bg-gray-200 px-2 py-1 rounded-full">Llama</button>
              <button className="bg-gray-200 px-2 py-1 rounded-full">Aleph</button>
            </div>
          </>
        )}

        {selectedElementLabel && renderCollapsibleBox(
          'Label Editor',
          isLabelEditorCollapsed,
          setIsLabelEditorCollapsed,
          <>
            <label className="block mb-2">Label:
              <input
                type="text"
                value={selectedLabel}
                onChange={(e) => handleLabelUpdate(e.target.value)}
                className="border p-2 mt-1 w-full"
              />
            </label>
            <button className="bg-green-500 text-white p-2 mt-4 w-full" onClick={handleApplyLabelChanges}>
              Apply Changes
            </button>
          </>
        )}
      </div>

      {/* Diagram Area */}
      <main className="flex-grow p-4 ml-64">
        <MermaidDiagram
          initialChart={fileContent}
          onDiagramGenerated={setDiagramPng}
          onLabelSelect={(label: string) => {
            setSelectedLabel(label); 
            setSelectedElementLabel(label);
          }}
        />
      </main>

      <ChatBox onFileContentUpdate={handleFileContentUpdate} selectedProvider={'CloudAhead'} />
    </div>
  );
};

export default MainLayout;
