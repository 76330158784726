import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  element: JSX.Element; // The protected element to be rendered
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading spinner or message
  }

  return isAuthenticated ? element : <Navigate to="/" />; // Redirect to homepage if not authenticated
};

export default ProtectedRoute;
